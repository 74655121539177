<template>
<div :key="`key_${title}_${key}`">
    <GeneralSubHeader>
        <template #title>
            <!-- <span
                v-if="anecdotalEvent.categoryIcon"
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                {{ anecdotalEvent.categoryIcon }}
            </span> -->
            <SVGIcon
                hex-color="#0f88ef"
                class="mt-0 mr-3"
                name="anecdotal"
            />

            Anecdotal Logs

            <!-- <small class="ml-3">{{ lastUpdated }}</small> -->
        </template>
        <template #left-buttons>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                :title="showDeleted ? 'Hide Deleted' : 'Show Deleted'"
                class="btn btn-icon"
                :class="{ 'btn-danger': showDeleted, 'btn-clean-danger btn-clean': !showDeleted }"
                @click.stop.prevent=""
            >
                <i
                    class="flaticon2-trash"
                />
            </a>
        </template>
        <template #buttons>
            <b-dropdown
                toggle-class="btn btn-clean btn-sm"
                variant="none"
                size="md"
                text="2022-2023 Term 1"
                class="btn-wide btn-clean btn-clean-primary"
            >
                <template #button-content>
                    <span class="pull-left">
                        {{ schoolTerm ? schoolTerm.name : 'Yearlong' }}
                    </span>
                </template>
                <b-dropdown-item
                    @click.stop.prevent="applyTermFilter(null)"
                >
                    Yearlong
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="term in schoolTerms"
                    :key="term.schoolTermId"
                    @click.stop.prevent="applyTermFilter(term)"
                >
                    {{ term.name }}
                </b-dropdown-item>
            </b-dropdown>
            <a
                href="#"
                class="btn btn-clean btn-icon"
                @click.stop.prevent="newAnecdotal"
            >
                <SVGIcon
                    name="file-plus"
                    class="kt-svg-icon kt-svg-icon--primary kt-svg-icon--md pull-left"
                />
            </a>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Export To CSV"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="exportData"
            >
                <i class="flaticon-download-1" />
            </a>
        </template>
    </GeneralSubHeader>
    <div
        :class="[lockByStudentId ? 'pl-0 pr-0' : '']"
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
        <AnecdotalLogs />
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import { mapState } from 'vuex';
import _ from 'lodash';
// import AnecdotalTotals from '../components/AnecdotalTotals.vue';
import AnecdotalLogs from './anecdotals/AnecdotalLogs.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import Types from '../store/Types';
import SVGIcon from '../components/SVGIcon/SVGIcon.vue';
import userMixins from '../store/mixins/userMixins';

export default Vue.extend({
    name: 'SchoolAnecdotalLogs',
    components: {
        AnecdotalLogs,
        SVGIcon,
        GeneralSubHeader,
        // AnecdotalTotals,
    },
    mixins: [
        userMixins,
    ],
    props: {
        lockByStudentId: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            showDeleted: false,
            key: 0,
            schoolTerm: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventSearch: (state) => state.database.anecdotalEventSearch,
            forceStudentView: (state) => state.forceStudentView,
        }),
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        isStudentView() {
            const homeUser = this.$_userMixins_isHomeUser;
            const schoolUser = this.$_userMixins_isSchoolUser;

            if (homeUser) return true;
            if (schoolUser) {
                if (this.forceStudentView) {
                    return true;
                }
                return false;
            }
            return true;
        },
        role() {
            const { role } = this.$store.state.user.school;
            if (this.forceStudentView) {
                return 'Student';
            }
            return role;
        },
        hasPrevPage() {
            return this.anecdotalEventSearch.pageNumber > 1;
        },
        hasNextPage() {
            return this.anecdotals.length == this.anecdotalEventSearch.pageSize;
        },
        anecdotalEventDate: {
            get() {
                return this.anecdotalEventSearch.anecdotalEventDate;
            },
            set(value) {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE, value);
                this.searchAnecdotals();
            },
        },
        anecdotalPointValue: {
            get() {
                return this.anecdotalEventSearch.anecdotalPointValue;
            },
            set(value) {
                const { anecdotalPointValue } = this.anecdotalEventSearch;

                const newValue = value == '' ? null : value;
                if (newValue == null) {
                    this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                }
                if (newValue == 'positives') {
                    if (anecdotalPointValue == newValue) {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                    } else {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, newValue);
                    }
                }
                if (newValue == 'negatives') {
                    if (anecdotalPointValue == newValue) {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, null);
                    } else {
                        this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE, newValue);
                    }
                }
                this.searchAnecdotals();
            },
        },
        anecdotalEventDescription: {
            get() {
                return this.anecdotalEventSearch.anecdotalEventDescription || '';
            },
            set: _.debounce(function(value)  {
                if (value !== this.anecdotalEventDescription) { // Prevents unnecessary triggers
                    this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION, value || '');
                    this.searchAnecdotals();
                }
            }, 500),
        },
        anecdotals() {
            const { showDeleted, isStudentView } = this;
            const { students, teachers } = this.$store.state.database;

            return this.anecdotalEvents.map((e, idx) => {
                const event = e;

                event.search = `${event.anecdotalEventDescription} ${event.anecdotalEventCategoryTitle}`.toLowerCase();
                event.vueId = `anecdotalEventId_${event.anecdotalEventId}_${idx}`;
                event.sort = `${event.anecdotalEventDate}`;

                const eventStudents = (event.students || []).map((s) => {
                    const student = students.find((x) => x.studentEnrollmentId == s.studentEnrollmentId);
                    if (!student) return null;
                    const item = { ...student };
                    item.search = `${event.search} ${student.lastName} ${student.firstName}`.toLowerCase();
                    return item;
                }).filter((x) => Boolean(x));

                const eventTeachers = (event.teachers || []).map((s) => {
                    const teacher = teachers.find((x) => x.schoolStaffId == s.schoolStaffId);
                    if (!teacher) return null;
                    const item = { ...teacher };
                    item.search = `${event.search} ${teacher.lastName} ${teacher.firstName}`.toLowerCase();
                    return item;
                }).filter((x) => Boolean(x));

                event.participants = [...eventStudents, ...eventTeachers];

                return event;
            }).filter((e) => {
                if (showDeleted && !isStudentView) return e.deleted;
                return !e.deleted;
            });
        },
        ...mapState({
            user: (state) => state.user,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
            students: (state) => state.database.students,
        }),
        schoolTerms() {
            return this.$store.state.database.schoolTerms;
        },
    },
    watch: {
        forceStudentView() {
            this.populate();
        },
    },
    mounted() {
        // [this.schoolTerm] = this.schoolTerms;
        // const { schoolTermId } = this.schoolTerm;
        // if (schoolTermId) this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM, schoolTermId);
        this.populate();
    },
    methods: {
        populate() {
            if (this.lockByStudentId) {
                this.$store.commit(Types.mutations.SET_DB_ANECDOTAL_EVENTS, []); // clear cached
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, this.lockByStudentId);
            } else {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, null);
            }
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        clearDate() {
            this.anecdotalEventDate = null;
        },
        download() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL, true);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        reset() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF, null);
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT, null);
        },
        newAnecdotal() {
            if (this.lockByStudentId) {
                const student = this.students.find((x) => x.studentId == this.lockByStudentId);
                if (!student) return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
                return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN, { students: [student] });
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
        },
        prevPage() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_PAGE_NUMBER, this.anecdotalEventSearch.pageNumber - 1);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        nextPage() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_PAGE_NUMBER, this.anecdotalEventSearch.pageNumber + 1);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        searchAnecdotals() {
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        navigate(anecdotalEventId) {
            if (this.$route.name == 'StudentPortfolio') {
                const params = { ...this.$route.params, ...{ detailId: anecdotalEventId } };
                this.$router.push({ name: 'StudentPortfolio', params });
            } else {
                this.$router.push({ name: 'ViewAnecdotal', params: { anecdotalEventId } });
            }
        },
        applyTermFilter(term) {
            this.schoolTerm = term;
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM, term ? term.schoolTermId : null);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        exportData() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL, true);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
    },
});
</script>

<style scoped>

div.no-wrap {
    white-space: nowrap;
}
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
  border-bottom: 1px solid #ebedf2;
}

tr td {
    cursor: pointer;
}

td.td-col-1 {
    vertical-align: middle;
    width: 72px;
}

td.td-col-2 {
    width: 600px;
}

td.td-col-3 {
    vertical-align: middle;
    width: 140px;
}

td.td-col-4 {
    vertical-align: middle;
    width: 300px;
}
.kt-widget__media {
    margin-left: 40px;
}
.kt-media-group .kt-media {
    margin-left: -22px;
}
.kt-media-group .kt-media:first-child {
    margin-left: 40px;
}
</style>
