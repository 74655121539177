var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "mt-0 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "anecdotal" },
                }),
                _vm._v(" Anecdotal Logs "),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon",
                    class: {
                      "btn-danger": _vm.showDeleted,
                      "btn-clean-danger btn-clean": !_vm.showDeleted,
                    },
                    attrs: {
                      href: "#",
                      title: _vm.showDeleted ? "Hide Deleted" : "Show Deleted",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon2-trash" })]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "btn-wide btn-clean btn-clean-primary",
                    attrs: {
                      "toggle-class": "btn btn-clean btn-sm",
                      variant: "none",
                      size: "md",
                      text: "2022-2023 Term 1",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "pull-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.schoolTerm
                                      ? _vm.schoolTerm.name
                                      : "Yearlong"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.applyTermFilter(null)
                          },
                        },
                      },
                      [_vm._v(" Yearlong ")]
                    ),
                    _vm._l(_vm.schoolTerms, function (term) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: term.schoolTermId,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.applyTermFilter(term)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(term.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-clean btn-icon",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.newAnecdotal.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("SVGIcon", {
                      staticClass:
                        "kt-svg-icon kt-svg-icon--primary kt-svg-icon--md pull-left",
                      attrs: { name: "file-plus" },
                    }),
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { href: "#", title: "Export To CSV" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exportData.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          class: [_vm.lockByStudentId ? "pl-0 pr-0" : ""],
        },
        [_c("AnecdotalLogs")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }